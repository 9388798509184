<template>
  <div class="row">
    <div class="col-lg-6">
      <div class="card card-custom card-stretch gutter-b">
        <div class="card-header h-auto border-0">
          <div class="card-title py-5">
            <h3 class="card-label">
              <span class="d-block text-dark font-weight-bolder">Today's Job Data</span>
              <span class="d-block text-muted mt-2 font-size-sm">Glance at the progress for the day</span>
            </h3>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 col-sm-12 d-flex flex-column">
              <today-job-listview v-model="todayjob" />
            </div>
            <div class="col-lg-8 col-sm-12">
              <today-job-pie-chart v-model="todayjob" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="card card-custom card-stretch gutter-b">
        <div class="card-header h-auto border-0">
          <div class="card-title py-5">
            <h3 class="card-label">
              <span class="d-block text-dark font-weight-bolder">Monthly's Job Data</span>
              <span class="d-block text-muted mt-2 font-size-sm">Glance at the progress for the month</span>
            </h3>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 col-sm-12 d-flex flex-column">
              <today-job-listview v-model="monthlyjob" />
            </div>
            <div class="col-lg-8 col-sm-12">
              <today-job-pie-chart v-model="monthlyjob" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-8">
      <div class="card card-custom card-stretch gutter-b">
        <div class="card-header h-auto border-0">
          <div class="card-title py-5">
            <h3 class="card-label">
              <span class="d-block text-dark font-weight-bolder">Today's Trip Data</span>
              <span class="d-block text-muted mt-2 font-size-sm">Glance at the progress for the day</span>
            </h3>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-4 col-sm-12 d-flex flex-column">
              <today-trip-listview v-model="todaytrip" />
            </div>
            <div class="col-lg-8 col-sm-12">
              <today-trip-pie-chart v-model="todaytrip" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted } from "@vue/composition-api";
import { getDashboard } from "@/api/client-operation.api";

import TodayJobPieChart from "@/components/dashboard/TodayJobPieChart";
import TodayJobListview from "@/components/dashboard/TodayJobListview";
import TodayTripListview from "@/components/dashboard/TodayTripListview";
import TodayTripPieChart from "@/components/dashboard/TodayTripPieChart";
export default {
  components: { TodayJobPieChart, TodayJobListview, TodayTripListview, TodayTripPieChart },

  setup() {
    const todayjob = reactive({
      openjob: 0,
      closejob: 0,
      canceljob: 0
    });

    const monthlyjob = reactive({
      openjob: 0,
      closejob: 0,
      canceljob: 0
    });

    const todaytrip = reactive({
      newtrip: 0,
      ongoingtrip: 0,
      completedtrip: 0,
      failtrip: 0,
      canceltrip: 0,
      inactivetrip: 0
    });

    onMounted(() => {
      getDashboard().then(response => {
        const data = response.data;

        todayjob.openjob = data.today_open_job_count;
        todayjob.closejob = data.today_close_job_count;
        todayjob.canceljob = data.today_cancel_job_count;

        monthlyjob.openjob = data.monthly_open_job_count;
        monthlyjob.closejob = data.monthly_close_job_count;
        monthlyjob.canceljob = data.monthly_cancel_job_count;

        todaytrip.newtrip = data.today_new_trip_count;
        todaytrip.ongoingtrip = data.today_ongoing_trip_count;
        todaytrip.completedtrip = data.today_completed_trip_count;
        todaytrip.failtrip = data.today_fail_trip_count;
        todaytrip.canceltrip = data.today_cancel_trip_count;
        todaytrip.inactivetrip = data.today_inactive_trip_count;
      });
    });

    return {
      todayjob,
      monthlyjob,
      todaytrip
    };
  }
};
</script>
